import { Spin } from "antd";
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { GeneralProvider } from "./contexts/GeneralProvider";
import "./index.css";
import { ProveItApp } from "./ProveItApp";
import { reportWebVitals } from "./utils/reportWebVitals";
import Pricing from "./pages/Pricing";

const About = lazy(() => import("./pages/About"));
const Admin = lazy(() => import("./pages/Admin"));
const Error = lazy(() => import("./pages/Error"));
const Trending = lazy(() => import("./pages/Trending"));
const Login = lazy(() => import("./pages/Login"));
const Market = lazy(() => import("./pages/Market"));
const PaymentSuccess = lazy(() => import("./pages/PaymentSuccess"));
const Predictions = lazy(() => import("./pages/Predictions"));
const PredictionsBlank = lazy(() => import("./pages/PredictionsBlank"));
const Ranking = lazy(() => import("./pages/Rankings"));
const Registration = lazy(() => import("./pages/Registration"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const UserProfile = lazy(() => import("./pages/UserProfile"));

// Apply theme class to body before rendering
if (localStorage.getItem("theme") === "dark") {
  document.body.classList.add("dark");
} else {
  document.body.classList.remove("dark");
}

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <BrowserRouter>
        <GeneralProvider>
          <ProveItApp>
            <Suspense fallback={<RouteLoader />}>
              <Routes>
                {/* When adding routes, please add to components > NavBarSelector */}
                <Route index element={<About />} />
                <Route path="/pricing" element={<Pricing/>} />
                <Route path="/trending" element={<Trending />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Registration />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/predictions" element={<PredictionsBlank />} />
                <Route path="/predictions/:ticker" element={<Predictions />} />
                <Route path="/market" element={<Market />} />
                <Route path="/rankings" element={<Ranking />} />
                <Route path="/user/:username" element={<UserProfile />} />
                <Route path="/payment-success" element={<PaymentSuccess />} />
                <Route path="/error" element={<Error />} />
                <Route path="*" element={<Navigate to="" replace />} />
              </Routes>
            </Suspense>
          </ProveItApp>
        </GeneralProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>,
);

// Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) {
  return (
    <div className="fixed z-[999] flex h-dvh w-dvw items-center justify-center bg-background/25 backdrop-blur">
      <div className="flex flex-col items-center justify-center gap-4 bg-background p-4">
        <h1 className="text-center text-3xl">An error occurred.</h1>
        <pre className="text-destructive">{error.message}</pre>
        <button
          className="rounded-md border border-accent px-3.5 py-1.5 transition-colors hover:border-primary hover:text-primary"
          onClick={resetErrorBoundary}
        >
          Try Again
        </button>
      </div>
    </div>
  );
}

function RouteLoader() {
  return (
    <div
      className="flex w-full items-center justify-center"
      style={{
        minHeight: "calc(100vh - 48px)",
      }}
    >
      <Spin size="large" />
    </div>
  );
}
