import { LoadingOutlined } from "@ant-design/icons";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { App, ConfigProvider, Spin, theme as antTheme } from "antd";
import { defaults } from "chart.js";
import { lazy, Suspense, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { AlertOnLeave } from "./components/AlertOnLeave";
import NavBarSelector from "./components/NavBarSelector";
import { useGeneralContext } from "./contexts/GeneralProvider";

const NavBarApp = lazy(() =>
  import("@/components/NavBars").then((module) => ({
    default: module.NavBarApp,
  })),
);
const NavBarInfo = lazy(() =>
  import("@/components/NavBars").then((module) => ({
    default: module.NavBarInfo,
  })),
);

export function ProveItApp({ children }: { children: React.ReactNode }) {
  defaults.font.family = "Noto Sans";

  const [queryClient] = useState(() => new QueryClient());
  Spin.setDefaultIndicator(<LoadingOutlined spin />);

  const location = useLocation();

  const { axiosInstance, theme, currentUser, setCurrentUser } =
    useGeneralContext();

  console.log("location", location);
  const showDecryptorFab =
    location.pathname.startsWith("/trending") ||
    location.pathname.startsWith("/predictions");

  // Move lazy load here so it's only initialized when needed
  const DecryptorFab = showDecryptorFab
    ? lazy(() =>
        import("@/components/decryptor/DecryptorFab").then((module) => ({
          default: module.DecryptorFab,
        })),
      )
    : null;

  // Save the theme to local storage on change
  useEffect(() => {
    if (theme === "dark") {
      document.body.classList.add("dark");
      localStorage.setItem("theme", "dark");
      // query select from doc to change the theme color (meta tag)
      document
        .querySelector("meta[name=theme-color]")
        ?.setAttribute("content", "#000000");
    } else {
      document.body.classList.remove("dark");
      localStorage.setItem("theme", "light");
      document
        .querySelector("meta[name=theme-color]")
        ?.setAttribute("content", "#193730");
    }
  }, [theme]);

  // Set the currentUser from local storage on component mount
  useEffect(() => {
    const storedUser = localStorage.getItem("currentUser");
    if (storedUser) {
      setCurrentUser(JSON.parse(storedUser));
    }
  }, [setCurrentUser]);

  // Save the currentUser to local storage on change
  useEffect(() => {
    if (currentUser) {
      localStorage.setItem(
        "currentUser",
        JSON.stringify({ ...currentUser, password: undefined }),
      );
    } else {
      localStorage.removeItem("currentUser");
    }
  }, [currentUser]);

  // Fetch the current user on component mount
  useEffect(() => {
    async function fetchCurrentUser() {
      try {
        const { data } = await axiosInstance.get("/auth/me");
        setCurrentUser((prev) => (prev ? { ...prev, ...data } : { ...data }));
      } catch (error) {
        console.error(error);
        try {
          await axiosInstance.get("/auth/logout");
          setCurrentUser(undefined);
        } catch (error) {
          console.error(error);
        }
      }
    }

    fetchCurrentUser();
  }, [axiosInstance, setCurrentUser]);

  return (
    <ConfigProvider
      theme={{
        algorithm:
          theme === "dark" ? antTheme.darkAlgorithm : antTheme.defaultAlgorithm,
        token: {
          fontFamily: "Noto Sans, -apple-system, sans-serif",

          fontSizeSM: 14,
          fontSize: 16,
          fontSizeLG: 18,
          fontSizeXL: 20,

          borderRadiusXS: 12,
          borderRadiusSM: 16,
          borderRadius: 20,
          borderRadiusLG: 24,
          borderRadiusOuter: 32,

          colorPrimary: theme === "dark" ? "#FF9500" : "#193730",
          colorHighlight: theme === "dark" ? "#193730" : "#FF9500",
        },
        components: {
          Tooltip: {
            paddingXS: 14,
            paddingSM: 14,
            fontSize: 14,
          },
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <App>
          <Suspense>
            <AlertOnLeave />
          </Suspense>
          <Suspense>
            <NavBarSelector />
          </Suspense>
          {children}
          {showDecryptorFab && DecryptorFab && (
            <Suspense>
              <DecryptorFab />
            </Suspense>
          )}
        </App>
      </QueryClientProvider>
    </ConfigProvider>
  );
}
