import { RawPrediction } from "@/types/common";
import { decryptApiData, transformApiData } from "@/utils/apiResponse";
import { decryptString } from "@/utils/encryption";
import { hashPrediction } from "@/utils/hashing";
import axios, { AxiosInstance } from "axios";
import { useMemo } from "react";

export function useAxiosInstance(
  setCurrentUser: (undefined: undefined) => void,
  password: string | undefined,
): AxiosInstance {
  const axiosInstance = useMemo(() => {
    // Create an Axios instance
    const instance = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      withCredentials: true, // Default config to include credentials
    });

    // Add a request interceptor
    instance.interceptors.request.use(
      async (request) => {
        // Check if the original request was to /auth/verify-password
        if (request.url?.includes("/auth/verify-password")) {
          const password: string | undefined = request.data.password;
          // If the password is provided, call onVerifyPassword
          if (password) await onVerifyPassword(password, instance);
        }
        return request;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    // Add a response interceptor
    instance.interceptors.response.use(
      async (response) => {
        // Requests to not transform data
        const noTransform = [
          "/predictions/immature",
          "/predictions/pending-publication",
        ];

        // if the request is from ... we can just return the response now
        if (noTransform.some((path) => response.config.url?.includes(path))) {
          return response;
        }

        // Transform data from snake_case to camelCase
        response.data = await transformApiData(response.data);

        // Auto-decrypt encrypted values if password is available
        if (password) {
          response.data = await decryptApiData(response.data, password);
        }

        // Return the response to the caller
        return response;
      },
      async (error) => await onResponseError(error, instance, handleLogout),
    );

    async function handleLogout() {
      try {
        await instance.get("/auth/logout");
        setCurrentUser(undefined);
      } catch (error) {
        console.error("handleLogout", error);
      }
    }

    return instance;
  }, [password, setCurrentUser]);

  return axiosInstance;
}

async function onVerifyPassword(
  password: string,
  axiosInstance: AxiosInstance,
) {
  try {
    // 1. Fetch all pending mature predictions
    const pendingResponse = await axiosInstance.get(
      "/predictions/pending-publication",
    );

    const pendingPredictions: Array<RawPrediction> = pendingResponse.data;

    if (pendingPredictions.length > 0) {
      // 2. Prepare update data with decryptedValue and hash_value
      const updateData: {
        id: string;
        value: number;
        hash_value: string;
      }[] = await Promise.all(
        pendingPredictions.map(async (prediction) => {
          if (prediction.encrypted_value === undefined) {
            throw new Error("Prediction does not have an encrypted value");
          }

          const decryptedValue = Number(
            await decryptString(prediction.encrypted_value, password),
          );

          if (!decryptedValue) {
            throw new Error("Failed to decrypt value");
          }

          const hashedValue = hashPrediction({
            createdAt: prediction.created_at,
            ticker: prediction.ticker,
            decryptedValue: decryptedValue,
            datetime: prediction.datetime,
            multiplier: prediction.multiplier,
          });

          return {
            id: prediction.id,
            value: decryptedValue,
            hash_value: hashedValue,
          };
        }),
      );

      // 3. Send bulk update to server
      await axiosInstance.post("predictions/bulk-update-public", updateData);
    }
  } catch (error) {
    console.error("Error processing pending predictions:", error);
  }
}

async function onResponseError(
  error: any,
  instance: AxiosInstance,
  onLogout?: () => Promise<void>,
) {
  let apiMessage: string | undefined = undefined;

  // Handle different error scenarios
  if (!window.navigator.onLine) {
    apiMessage = "No internet connection. Please check your network.";
  } else if (
    error.code === "ECONNABORTED" ||
    error.message === "Network Error"
  ) {
    apiMessage = "Cannot connect to the server. Please try again later.";
  } else if (error?.response?.status === 403) {
    const originalRequest = error.config;
    if (!originalRequest._retry) {
      originalRequest._retry = true; // Mark the request as retried

      try {
        // Attempt to refresh the token
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/refresh`, {
          withCredentials: true,
        });

        // Retry the original request with new tokens
        return instance.request(originalRequest);
      } catch (refreshError) {
        // If token refresh fails, trigger logout
        if (onLogout) await onLogout();
        apiMessage = "Unauthorized. Please log in.";
      }
    } else {
      apiMessage = "Session expired. Please log in again.";
    }
  } else if (error?.response?.data?.detail) {
    apiMessage = error.response.data.detail;
  }

  if (apiMessage) error.apiMessage = apiMessage;
  return Promise.reject(error);
}
