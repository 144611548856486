import {
  base64URLStringToBuffer,
  bufferToBase64URLString,
} from "@simplewebauthn/browser";

// Encrypt a string using AES-GCM and return it as a Base64URL-encoded string
export async function encryptString(
  data: string,
  passKey: string | CryptoKey
): Promise<string | undefined> {
  const enc = new TextEncoder();

  // Generate a random salt for each encryption
  const salt = window.crypto.getRandomValues(new Uint8Array(16)); // 16 bytes salt

  let key: CryptoKey | undefined;

  if (typeof passKey === "string") {
    const keyMaterial = await window.crypto.subtle.importKey(
      "raw",
      enc.encode(passKey),
      { name: "PBKDF2" },
      false,
      ["deriveBits", "deriveKey"]
    );

    key = await window.crypto.subtle.deriveKey(
      {
        name: "PBKDF2",
        salt: salt,
        iterations: 100000,
        hash: "SHA-256",
      },
      keyMaterial,
      { name: "AES-GCM", length: 256 },
      false,
      ["encrypt", "decrypt"]
    );
  } else {
    key = passKey;
  }

  if (!key) return;

  const iv = window.crypto.getRandomValues(new Uint8Array(12));
  const encrypted = await window.crypto.subtle.encrypt(
    { name: "AES-GCM", iv: iv },
    key,
    enc.encode(data)
  );

  // Combine Salt, IV, and encrypted data and return as a Base64URL-encoded string
  const combined = new Uint8Array(
    salt.length + iv.length + encrypted.byteLength
  );
  combined.set(salt, 0);
  combined.set(iv, salt.length);
  combined.set(new Uint8Array(encrypted), salt.length + iv.length);

  return bufferToBase64URLString(combined.buffer);
}

// Decrypt a Base64URL-encoded string using AES-GCM and return the decrypted string
export async function decryptString(
  encryptedData: string,
  passKey: string | CryptoKey
): Promise<string | undefined> {
  const combinedBuffer = base64URLStringToBuffer(encryptedData);

  const salt = combinedBuffer.slice(0, 16); // Extract the Salt (first 16 bytes)
  const iv = combinedBuffer.slice(16, 28); // The IV (next 12 bytes)
  const encrypted = combinedBuffer.slice(28); // The encrypted data

  let key: CryptoKey | undefined;

  if (typeof passKey === "string") {
    const enc = new TextEncoder();
    const keyMaterial = await window.crypto.subtle.importKey(
      "raw",
      enc.encode(passKey),
      { name: "PBKDF2" },
      false,
      ["deriveBits", "deriveKey"]
    );

    key = await window.crypto.subtle.deriveKey(
      {
        name: "PBKDF2",
        salt: salt,
        iterations: 100000,
        hash: "SHA-256",
      },
      keyMaterial,
      { name: "AES-GCM", length: 256 },
      false,
      ["encrypt", "decrypt"]
    );
  } else {
    key = passKey;
  }

  if (!key) return;

  const decrypted = await window.crypto.subtle.decrypt(
    { name: "AES-GCM", iv: iv },
    key,
    encrypted
  );

  return new TextDecoder().decode(decrypted);
}

// Function to derive a key from a string using PBKDF2
export async function deriveKeyFrom(str: string): Promise<CryptoKey> {
  const enc = new TextEncoder();
  const keyMaterial = await window.crypto.subtle.importKey(
    "raw",
    enc.encode(str),
    { name: "PBKDF2" },
    false,
    ["deriveBits", "deriveKey"]
  );

  // Use a consistent salt and parameters for key derivation
  return window.crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt: enc.encode("salt"), // For demonstration, use a static salt; in production, use a unique salt per user.
      iterations: 100000,
      hash: "SHA-256",
    },
    keyMaterial,
    { name: "AES-GCM", length: 256 },
    false,
    ["encrypt", "decrypt"]
  );
}
