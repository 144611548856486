import { useAxiosInstance } from "@/hooks/useAxiosInstance";
import { Prediction, User } from "@/types/common";
import { AxiosInstance } from "axios";
import { createContext, ReactNode, useContext, useState } from "react";

type CurrentUser = User & {
  rank?: number;
  percentile?: number;
  latestPrediction?: Prediction;
  password?: string;
};

type Theme = "light" | "dark";

interface GeneralContextProps {
  theme: Theme;
  setTheme: React.Dispatch<React.SetStateAction<Theme>>;
  currentUser: CurrentUser | undefined;
  setCurrentUser: React.Dispatch<React.SetStateAction<CurrentUser | undefined>>;
  axiosInstance: AxiosInstance;
}

const GeneralContext = createContext<GeneralContextProps | undefined>(
  undefined,
);

interface GeneralProviderProps {
  children: ReactNode;
}

export function GeneralProvider({ children }: GeneralProviderProps) {
  const [theme, setTheme] = useState<Theme>(getInitialTheme());
  const [currentUser, setCurrentUser] = useState<CurrentUser>();

  // Use the custom hook to get axios instance
  const axiosInstance = useAxiosInstance(setCurrentUser, currentUser?.password);

  return (
    <GeneralContext.Provider
      value={{
        theme,
        setTheme,
        currentUser,
        setCurrentUser,
        axiosInstance,
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
}

export const useGeneralContext = () => {
  const context = useContext(GeneralContext);
  if (context === undefined) {
    throw new Error("useGeneralContext must be used within a GeneralProvider");
  }
  return context;
};

function getInitialTheme(): Theme {
  // Check for user preference
  const savedTheme = localStorage.getItem("theme");
  if (savedTheme) return savedTheme === "dark" ? "dark" : "light";

  // Default to system preference
  const prefersDarkScheme = window.matchMedia(
    "(prefers-color-scheme: dark)",
  ).matches;
  return prefersDarkScheme ? "dark" : "light";
}
