// NavBarWithItems.js
import React from "react";
import { NavBarBase } from "@/components/NavBarBase";

const navItemsApp = [
    {
      key: "trending",
      to: "/trending",
      label: "Trending",
    },
    {
      key: "prediction",
      to: "/predictions",
      label: "Predictions",
    },
    {
      key: "market",
      to: "/market",
      label: "Market",
    },
    {
      key: "ranking",
      to: "/rankings",
      label: "Rankings",
    },
  ];

  const navItemsInfo = [
    {
      key: "home",
      to: "/",
      label: "Overview",
    },
    {
      key: "pricing",
      to: "/pricing",
      label: "Pricing & Plans",
    },
    {
      key: "trending",
      to: "/trending",
      label: "Try it Out!",
    },
  ];

export function NavBarApp() {
  return <NavBarBase navItems={navItemsApp}/>;
}

export function NavBarInfo() {
  return <NavBarBase navItems={navItemsInfo}/>;
}
