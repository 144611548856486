import { useGeneralContext } from "@/contexts/GeneralProvider";
import { logOut } from "@/utils/common";
import { LogoutOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Button } from "antd";
import { useState } from "react";

export function LogOutButton({ useIcon = true }: { useIcon?: boolean }) {
  const reactQueryClient = useQueryClient();
  const { setCurrentUser } = useGeneralContext();

  const [loading, setLoading] = useState(false);

  return (
    <Button
      loading={loading}
      icon={useIcon ? <LogoutOutlined /> : undefined}
      onClick={async () => {
        setLoading(true);
        await logOut({
          setCurrentUser: setCurrentUser,
          reactQueryClient: reactQueryClient,
        });
        setLoading(false);
      }}
    >
      <span className="ml-auto">Log Out</span>
    </Button>
  );
}
