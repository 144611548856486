import { useGeneralContext } from "@/contexts/GeneralProvider";
import { cn } from "@/utils/common";
import { UserOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { App, Avatar, Spin } from "antd";
import { useState } from "react";

const profilePictureInputId = "profile-picture-upload";

export function getProfilePictureUrl(
  img: string | undefined,
): string | undefined {
  return img
    ? `${process.env.NODE_ENV === "development" ? "http" : "https"}://${process.env.REACT_APP_API_DOMAIN}${img}`
    : undefined;
}

export function ProfilePicture({
  img,
  pageUsername,
}: {
  img: string | undefined;
  pageUsername: string;
}) {
  const { message } = App.useApp();
  const queryClient = useQueryClient();
  const { axiosInstance, currentUser } = useGeneralContext();
  const [isUploading, setIsUploading] = useState(false);

  async function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedFile = event.currentTarget.files?.[0];
    if (!selectedFile) return;

    // Check file is .png or .jpg or .jpeg
    if (!["image/png", "image/jpg", "image/jpeg"].includes(selectedFile.type)) {
      message.error("Invalid image type. Only JPEG and PNG are allowed.");
      return;
    }

    // Check if file is under 2 MB
    if (selectedFile.size > 2 * 1024 * 1024) {
      message.error("Image size is too large. Max size is 2MB.");
      return;
    }

    setIsUploading(true);

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await axiosInstance.post(
        "/users/me/profile-picture",
        formData,
      );
      const data = await response.data;
      message.success(data.detail ?? "Profile picture uploaded successfully.");
      queryClient.refetchQueries({
        exact: true,
        queryKey: ["user-rank", currentUser?.username],
      });
    } catch (error: any) {
      message.error(error.apiMessage ?? "Error uploading profile picture");
      console.error("Error uploading profile picture:", error);
    }

    setIsUploading(false);
  }

  return (
    <>
      <div
        className={cn(
          "relative flex h-24 w-24 items-center justify-center overflow-hidden rounded-full border border-accent",
          currentUser?.username === pageUsername && "cursor-pointer",
          isUploading && "pointer-events-none",
        )}
        onClick={() => {
          if (!isUploading && currentUser?.username === pageUsername) {
            document.getElementById(profilePictureInputId)?.click();
          }
        }}
      >
        {isUploading && (
          <div className="absolute inset-0 z-10 flex items-center justify-center bg-white/50">
            <Spin />
          </div>
        )}
        <Avatar
          size={96}
          src={getProfilePictureUrl(img)}
          icon={<UserOutlined />}
          alt="Profile"
          className={cn(
            "transition-opacity",
            isUploading ? "opacity-50" : "opacity-100",
          )}
        />
      </div>

      {currentUser?.username === pageUsername && (
        <input
          hidden
          type="file"
          accept="image/*"
          id={profilePictureInputId}
          onChange={handleFileChange}
        />
      )}
    </>
  );
}
