import { decryptString } from "./encryption";

export function transformApiData(item: unknown, keyof?: string): unknown {
  // Check if item is null
  if (item === null) return undefined;

  // Check if item is an array
  if (Array.isArray(item)) {
    return item.map((el: unknown) => transformApiData(el));
  }

  // Check if item is not an object or is a function
  if (typeof item !== "object" || typeof item === "function") {
    // Handle date/datetime conversion for numbers
    if (
      typeof item === "number" &&
      (keyof === "date" ||
        keyof === "datetime" ||
        keyof === "timestamp" ||
        keyof === "created_at" ||
        keyof === "updated_at")
    ) {
      // Create a Date object from the UTC timestamp (to local time)
      const localDate = new Date(item);

      // Check if the date is valid
      if (!isNaN(localDate.getTime())) {
        return localDate;
      }
    }
    return item;
  }

  // Convert keys from snake_case to camelCase
  return Object.fromEntries(
    Object.entries(item as Record<string, unknown>).map(([key, value]) => [
      key.replace(/([-_][a-z])/gi, (c) => c.toUpperCase().replace(/[-_]/g, "")),
      transformApiData(value, key),
    ]),
  );
}

export async function decryptApiData(
  item: unknown,
  password: string,
): Promise<unknown> {
  // Check if item is an array
  if (Array.isArray(item)) {
    // Recursively apply decryption to each element in the array
    return await Promise.all(
      item.map((el: unknown) => decryptApiData(el, password)),
    );
  }

  // Check if item is an object (non-null and not a function)
  if (item !== null && typeof item === "object") {
    // Handle objects that specifically have 'encryptedValue' keys for decryption
    if ("encryptedValue" in item) {
      const encryptedValue = (item as Record<string, string | undefined>)
        .encryptedValue;

      // Check that the encryptedValue is a string
      if (typeof encryptedValue === "string") {
        try {
          // Decrypt the encryptedValue
          const decryptedValue = await decryptString(encryptedValue, password);

          // Return the item with decryptedValue added
          return { ...item, decryptedValue: Number(decryptedValue) };
        } catch (error) {
          console.error("Error decrypting value", error);
          return item; // Return the original item if decryption fails
        }
      }
    }

    // Recurse through each key-value pair in the object, ensuring not to alter non-encrypted items
    return Object.fromEntries(
      await Promise.all(
        Object.entries(item as Record<string, unknown>).map(
          async ([key, value]: [string, unknown]) => {
            // Directly return Date objects without modification
            if (value instanceof Date) {
              return [key, value];
            }

            // Don't recurse through encryptedValue nor decryptedValue fields
            if (key === "encryptedValue" || key === "decryptedValue") {
              return [key, value];
            }

            // Recursively process other fields
            return [key, await decryptApiData(value, password)];
          },
        ),
      ),
    );
  }

  // Return item as is for non-objects
  return item;
}
