import { Asset } from "@/types/common";
import { QueryClient } from "@tanstack/react-query";
import axios from "axios";
import { twMerge } from "tailwind-merge";
import { clsx, type ClassValue } from "clsx";

/** Calculate Percent Change
 * @param {number} old
 * @param {number} new
 * @returns {number}
 */
export function calcPercentChange(old: number, newPrice: number): number {
  return ((newPrice - old) / old) * 100;
}

/** Calculate first and last prices in AssetData */
export function getAssetPriceDetails(
  data: Asset["data"],
): { first: number; last: number; delta: number; pChange: number } | undefined {
  const firstPrice = data
    .map((data) => data.closePrice)
    .find((p) => p && !isNaN(p));

  if (!firstPrice) return undefined;

  const latestPrice = data
    .map((data) => data.closePrice)
    .reverse()
    .find((p) => p && !isNaN(p));

  if (!latestPrice) return undefined;

  return {
    first: firstPrice,
    last: latestPrice,
    delta: latestPrice - firstPrice,
    pChange: calcPercentChange(firstPrice, latestPrice),
  };
}

export async function logOut({
  setCurrentUser,
  reactQueryClient,
}: {
  setCurrentUser: (undefined: undefined) => void;
  reactQueryClient: QueryClient;
}) {
  try {
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/logout`, {
      withCredentials: true,
    });
    setCurrentUser(undefined);
    reactQueryClient.clear();
  } catch (error) {
    console.error("Error logging out", error);
  }
}

export function getCurrentEncodedRelativePath() {
  // Origin paths that should not create a new redirect param
  const originPaths = ["/login", "/register", "/reset-password"];

  const pathname = window.location.pathname;
  const searchParams = new URLSearchParams(window.location.search);
  const redirectParam = searchParams.get("redirect");

  // If we're on one of the specified paths
  if (originPaths.includes(pathname) || pathname === "/") {
    // If there's already a redirect param, return it encoded
    if (redirectParam) {
      return encodeURIComponent(redirectParam);
    }
    // Otherwise, return undefined
    return undefined;
  }

  // For other paths, return the encoded pathname and search params
  return encodeURIComponent(pathname + window.location.search);
}

export const PAGINATION_LIMIT = 10;

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export async function delay(ms: number) {
  return await new Promise((resolve) => setTimeout(resolve, ms));
}
