import { getProfilePictureUrl } from "@/components/profile/ProfilePicture";
import { useGeneralContext } from "@/contexts/GeneralProvider";
import { cn, getCurrentEncodedRelativePath } from "@/utils/common";
import { MenuOutlined, UserOutlined } from "@ant-design/icons";
import { CloseOutlined } from "@ant-design/icons";
import { Avatar, Button, Drawer, Typography } from "antd";
import { memo, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { LogOutButton } from "./nav/LogOutButton";

export const NavBarBase = memo(function NavBarBase({
  navItems,
}: {
  navItems: { key: string; to: string; label: string }[];
}) {
  const navigate = useNavigate();

  function handleRegisterClick() {
    const redirectPath = getCurrentEncodedRelativePath();
    if (redirectPath) navigate(`/register?redirect=${redirectPath}`);
    else navigate("/register");
  }

  function handleLoginClick() {
    const redirectPath = getCurrentEncodedRelativePath();
    if (redirectPath) navigate(`/login?redirect=${redirectPath}`);
    else navigate("/login");
  }

  return (
    <nav className="sticky left-0 right-0 top-0 z-40 flex h-12 w-full flex-row items-center justify-center bg-primary text-primary-foreground shadow-2xl">
      <MobileHeader
        onLoginClick={handleLoginClick}
        onRegisterClick={handleRegisterClick}
        navItems={navItems}
      />
      <DesktopHeader
        onLoginClick={handleLoginClick}
        onRegisterClick={handleRegisterClick}
        navItems={navItems}
      />
    </nav>
  );
});

const MobileHeader = memo(function MobileHeader({
  onLoginClick,
  onRegisterClick,
  navItems,
}: {
  onLoginClick: () => void;
  onRegisterClick: () => void;
  navItems: { key: string; to: string; label: string }[];
}) {
  const { theme, currentUser } = useGeneralContext();

  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const showDrawer = () => setIsDrawerOpen(true);
  const onClose = () => setIsDrawerOpen(false);

  useEffect(() => {
    setIsDrawerOpen(false);
  }, [location]);

  return (
    <div className="flex w-full max-w-7xl items-center justify-between px-4 md:hidden">
      <button onClick={showDrawer}>
        <MenuOutlined />
      </button>

      <Drawer
        placement="left"
        onClose={onClose}
        open={isDrawerOpen}
        closeIcon={<CloseOutlined className="text-primary-foreground" />}
        styles={{
          content: {
            color: theme === "dark" ? "#193730" : "#FF9500",
            backgroundColor: theme === "dark" ? "#FF9500" : "#193730",
          },
        }}
        title={
          <Typography.Title level={4} className="!m-0 !text-secondary">
            Sharpify
          </Typography.Title>
        }
      >
        <div className="flex h-full w-full flex-col items-center gap-4 overflow-y-auto">
          <NavItems
            className="flex w-full flex-col items-start gap-6"
            navItems={navItems}
          />

          <div className="mt-auto flex w-full flex-col gap-4">
            {currentUser ? (
              <>
                <Link
                  to={`/user/${currentUser.username}`}
                  className="group flex flex-col items-center"
                >
                  <Avatar
                    size={96}
                    src={getProfilePictureUrl(currentUser.profilePicture)}
                    alt="Profile"
                    icon={<UserOutlined />}
                    className="border-2 border-transparent transition-colors group-hover:border-secondary"
                  />
                  <Typography className="font-semibold text-primary-foreground transition-colors group-hover:text-secondary">
                    {currentUser.username}
                  </Typography>
                </Link>

                <LogOutButton />
              </>
            ) : (
              <>
                <Button onClick={onLoginClick}>Login</Button>
                <Button onClick={onRegisterClick}>Register</Button>
              </>
            )}
          </div>
        </div>
      </Drawer>

      <Link to="/" className="text-lg font-bold hover:text-secondary">
        Sharpify
      </Link>
    </div>
  );
});

const DesktopHeader = memo(function DesktopHeader({
  onLoginClick,
  onRegisterClick,
  navItems,
}: {
  onLoginClick: () => void;
  onRegisterClick: () => void;
  navItems: { key: string; to: string; label: string }[];
}) {
  const { currentUser } = useGeneralContext();

  return (
    <div className="hidden w-full max-w-7xl items-center justify-between px-4 md:flex">
      <div className="flex-1">
        <Link to="/" className="text-lg font-bold hover:text-secondary">
          Sharpify
        </Link>
      </div>

      <NavItems className="flex items-center gap-6" navItems={navItems} />

      <div className="flex flex-1 justify-end gap-2">
        {currentUser ? (
          <Link
            to={`/user/${currentUser.username}`}
            className="flex flex-row items-center justify-center gap-2"
          >
            <Typography className="font-semibold text-primary-foreground">
              {currentUser.username}
            </Typography>
            <Avatar
              size={32}
              src={getProfilePictureUrl(currentUser.profilePicture)}
              icon={<UserOutlined />}
              alt="Profile"
              className="ml-auto cursor-pointer select-none text-primary-foreground"
            />
          </Link>
        ) : (
          <>
            <Button onClick={onLoginClick}>Login</Button>
            <Button onClick={onRegisterClick}>Register</Button>
          </>
        )}
      </div>
    </div>
  );
});

export const NavItems = memo(function NavItems({
  className,
  navItems,
}: {
  className: string;
  navItems: { key: string; to: string; label: string }[];
}) {
  return (
    <div className={className}>
      {navItems.map((item) => (
        <NavLink
          key={item.key}
          to={item.to}
          className={({ isActive }) =>
            cn(
              "group relative pl-0 pr-2 text-base text-primary-foreground transition-all hover:text-secondary md:pl-2",
              isActive && "pointer-events-none",
            )
          }
        >
          {({ isActive }) => (
            <>
              {item.label}
              <span
                className={cn(
                  "absolute -bottom-1 left-0 h-0.5 w-full origin-left transform rounded-lg bg-primary-foreground opacity-0 transition-all group-hover:bg-secondary md:origin-center",
                  isActive ? "scale-x-100 opacity-100" : "scale-x-50 opacity-0",
                  "group-hover:scale-x-100 group-hover:opacity-100",
                )}
              />
            </>
          )}
        </NavLink>
      ))}
    </div>
  );
});
