import React from "react";
import { useLocation } from "react-router-dom";
import { NavBarApp, NavBarInfo } from "./NavBars"; // Lazy-loaded NavBar components

const NavBarSelector = () => {
  const location = useLocation();

  // Define lists of paths for each navbar
  const appNavPaths = [
    "/trending",
    "/predictions",
    "/market",
    "/rankings",
  ];

  const infoNavPaths = [
    "/",
    "/plans",
    "/pricing",
    "/about",  // Example of an additional info-related page
  ];

  // Logic to decide which NavBar to display based on current pathname
  if (appNavPaths.some(path => location.pathname.startsWith(path))) {
    return <NavBarApp />; // NavBar for general app pages
  }

  if (infoNavPaths.some(path => location.pathname.startsWith(path))) {
    return <NavBarInfo />; // NavBar for info-related pages
  }

  return <NavBarApp />; // Default to NavBarApp if no match
};

export default NavBarSelector;