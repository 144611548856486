import bcrypt from "bcryptjs";
import { sha256 } from "js-sha256";

// Function to hash the password, using bcrypt
export async function hashPassword(password: string) {
  // Static salt for the frontend password hashing
  const FRONTEND_STATIC_SALT = "$2a$12$6eLrmEAuAixFmKifV5N26u";

  const hashedPassword: string = await bcrypt.hash(
    password,
    FRONTEND_STATIC_SALT,
  );
  return hashedPassword;
}

// Function to hash the whole prediction object, using sha256 deterministic hashing
export function hashPrediction(prediction: {
  createdAt: number;
  ticker: string;
  decryptedValue: number;
  datetime: number;
  multiplier: number;
}): string {
  const predictionString = stringifyWithSortedKeys({
    createdAt: prediction.createdAt,
    ticker: prediction.ticker,
    decryptedValue: Number(prediction.decryptedValue).toFixed(2), // Convert to string (rounded to two decimal places)
    // encryptedValue: prediction.encryptedValue, // Do not include encrypted value in hash, as it is not deterministic and might change when user changes their password
    datetime: prediction.datetime,
    multiplier: prediction.multiplier,
  });
  console.log(" --- predictionString", predictionString);
  const hash = sha256(predictionString);
  return hash;
}

function stringifyWithSortedKeys(obj: Record<string, any>): string {
  const sortedKeys = Object.keys(obj).sort();
  let result = "{";
  for (let i = 0; i < sortedKeys.length; i++) {
    const key = sortedKeys[i];
    if (!key) continue;

    result += `"${key}":${JSON.stringify(obj[key])}`;
    if (i < sortedKeys.length - 1) {
      result += ",";
    }
  }
  result += "}";
  return result;
}
